@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

.root {
    max-width: 720px;
}

.steps {
    padding: 0;
    list-style: none;
}

.step {
    display: flex;
    margin-top: var(--spacing-core-8);
    align-items: center;
}

.stepText {
    word-break: break-word;
}

.circle {
    border-radius: 50%;
    min-width: var(--sizing-core-10);
    height: var(--sizing-core-10);
    line-height: 36px;
    border: var(--border-width-core-thick) solid var(--color-foreground-primary-default);
    color: var(--color-form-primary-default);
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    margin-right: var(--spacing-core-6);
}

@include mq.mq($from: large) {
    .circle {
        min-width: var(--sizing-core-12);
        height: var(--sizing-core-12);
        line-height: 44px;
        font-size: 24px;
    }
}
