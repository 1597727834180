$hover-transition: 250ms ease-out;

.root {
    width: 100%;
    background: var(--color-background-primary-default);
    box-shadow: 0 16px 32px rgb(0 0 0 / 16%);
    border-radius: var(--border-radius-core-s);
}

.list {
    padding: 0;
    margin: 0;
    list-style: none;
    color: #000;
}

.listItem {
    padding: 0;
    transition: background-color $hover-transition;

    &:first-of-type .listItemTextWrapper {
        border-top-color: transparent;
    }

    &:focus,
    &:hover,
    &.selectedItem {
        background-color: #f7f7f7;

        & + li .listItemTextWrapper,
        .listItemTextWrapper {
            border-top-color: transparent;
        }

        .listItemIcon {
            opacity: 1;
            transform: translateX(0) scale(1);
        }
    }

    &:active {
        background-color: #f1f1f1;
    }
}

.listItemWrapper {
    display: block;
    cursor: pointer;
    padding: 0 var(--spacing-core-6);
    background: none;
    border: none;
    width: 100%;
    text-align: left;
}

.listItemTextWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    border-top: 1px solid #e0e0e0;
    transition: border-color $hover-transition;
}

.listItemText {
    display: block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
}

.listItemIcon {
    opacity: 0;
    transform: translateX(-3px) scale(0.925);
    transition: opacity $hover-transition, transform $hover-transition;
    transform-origin: center center;
}
