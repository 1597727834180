@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

$small-promo-block-image-width: 105px;
$small-promo-block-image-width-phablet: 168px;
$small-promo-block-sticker-size: 56px;
$small-promo-block-sticker-size-phablet: 90px;

.background-color {
    &-ah {
        background-color: #d9f3fb;
    }

    &-bonus {
        background-color: #ffebd9;
    }

    &-salmiak {
        background-color: #fff;
    }

    &-drop {
        background-color: #e0e0e0;
    }

    &-chocolate {
        background-color: #e6e3e1;
    }

    &-mokka {
        background-color: #f4f1ed;
    }

    &-vanille {
        background-color: #fef7e6;
    }

    &-walnoot {
        background-color: #faf5ed;
    }

    &-pistache {
        background-color: #e9f5ed;
    }

    &-avocado {
        background-color: #eff6dd;
    }

    &-water {
        background-color: #edf6fc;
    }

    &-braam {
        background-color: #ede3ef;
    }

    &-aardbei {
        background-color: #fce2e2;
    }

    &-olijf {
        background-color: #f2f3e9;
    }

    &-haring {
        background-color: #f5fafa;
    }
}

.body {
    height: 146px;
    padding: var(--spacing-core-4);
    position: relative;

    :global(.theme__kerst) & {
        background-color: var(--color-accent-theme-holiday-default);
    }
}

.anchor {
    text-decoration: none;
    color: var(--color-foreground-primary-default);

    &:visited,
    &:hover,
    &:active,
    &:focus {
        color: var(--color-foreground-primary-default);
    }

    :global .svg {
        fill: var(--color-foreground-primary-default);
    }

    :global(.theme__kerst) & {
        color: var(--color-foreground-inverted-default);

        &:visited,
        &:hover,
        &:active,
        &:focus {
            color: var(--color-foreground-inverted-default);
        }

        :global .svg {
            fill: var(--color-foreground-inverted-default);
        }
    }
}

.content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-right: calc($small-promo-block-image-width - var(--spacing-core-4));
}

.imagePlaceholder {
    position: absolute;
    z-index: 20;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: $small-promo-block-image-width;
    height: 130px;

    img {
        width: $small-promo-block-image-width;
        height: 130px;
    }
}

.sticker {
    position: absolute;
    z-index: 10;
    width: $small-promo-block-sticker-size;
    height: $small-promo-block-sticker-size;
    top: 50%;
    transform: translateY(-50%);
    right: (
        ($small-promo-block-image-width - $small-promo-block-sticker-size) * 0.5
    );
}

@include mq.mq($from: small) {
    .body {
        height: 176px;
    }

    .content {
        padding-right: calc($small-promo-block-image-width-phablet -
            var(--spacing-core-6));
    }

    .sticker {
        width: $small-promo-block-sticker-size-phablet;
        height: $small-promo-block-sticker-size-phablet;
        right: (
            (
                    $small-promo-block-image-width-phablet -
                        $small-promo-block-sticker-size-phablet
                ) * 0.5
        );
    }

    .imagePlaceholder {
        transform: none;
        top: 0;
        right: 0;
        height: 100%;
        width: $small-promo-block-image-width;

        img {
            position: absolute;
            inset: 0;
            margin: auto;
            width: 100%;
            height: auto;
        }
    }
}

@include mq.mq($from: large) {
    .body {
        padding: var(--spacing-core-6);
    }

    .imagePlaceholder {
        transform: none;
        top: 0;
        right: 0;
        height: 100%;
        width: $small-promo-block-image-width-phablet;

        img {
            position: absolute;
            inset: 0 0 0 auto;
            margin: auto;
            width: auto;
            height: 100%;
        }
    }
}

/**
 * THEME: KERST
 */
.title {
    :global(.theme__kerst) & {
        color: var(--color-foreground-inverted-default);
    }
}
