.header {
    cursor: pointer;
    text-decoration: none;
    color: inherit;
}

.item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.selected {
    color: var(--color-action-primary-default);

    &.item {
        font-weight: 800;
        color: var(--color-action-primary-default);
    }
}

.outline {
    width: 100%;
    display: flex;
    justify-content: space-between;
    outline: none;
}

.disabled {
    opacity: 0.4;
    cursor: default;
}

