@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

.root {
    margin: var(--spacing-core-10) var(--spacing-core-4);
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    gap: var(--spacing-core-2) var(--spacing-core-4);

    @include mq.mq($until: small) {
        margin: var(--spacing-core-4) var(--spacing-core-4) 0 var(--spacing-core-4);
    }
}

.searchBarLink {
    display: flex;
    align-items: center;
    color: currentColor;
    text-decoration: none;
    font-weight: bold;
    transition: opacity 250ms ease;

    &:hover,
    &:focus {
        opacity: 0.8;
    }

    &:active {
        opacity: 0.6;
    }
}

.searchBarLinkIcon {
    margin: var(--spacing-core-2);
    color: #FFF;
    fill: #FFF;
}

.icon {
    display: inline-block;
    margin-top: 3px;
    flex-shrink: 0;
    margin-left: var(--spacing-core-2);
}
