@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

.root {
    position: relative;
    height: 592px;
    color: #FFF;

    @include mq.mq($until: medium) {
        height: 408px;
    }

    @include mq.mq($until: small) {
        height: 256px;
    }
}

.image {
    border-radius: var(--spacing-core-4);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-top: auto;
    object-position: center center;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgb(0 0 0 / 0%) 0%, #000 100%);
    opacity: 0.6;
}

.image,
.overlay {
    border-radius: var(--spacing-core-4);
}

.contentWrapper {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.heading {
    color: currentColor;
    text-align: center;
    margin-bottom: var(--spacing-core-8);
    text-shadow: 0 0 12px rgb(0 0 0 / 25%);

    @include mq.mq($until: small) {
        margin-bottom: var(--spacing-core-4);
    }
}

.searchBarLink {
    display: flex;
    margin-top: var(--spacing-core-10);
    color: currentColor;
    text-decoration: none;
    font-weight: bold;
    transition: opacity 250ms ease;

    &:hover,
    &:focus {
        opacity: 0.8;
    }

    &:active {
        opacity: 0.6;
    }
}

.searchBarLinkIcon {
    margin-left: var(--spacing-core-2);
    color: #FFF;
    fill: #FFF;
}

.bottom {
    $side-position: var(--spacing-core-12);

    position: absolute;
    display: none;
    justify-content: space-between;
    align-items: flex-end;
    left: $side-position;
    bottom: var(--spacing-core-10);
    width: calc(100% - calc(#{$side-position} * 2));
    z-index: 2;

    @include mq.mq($until: medium) {
        bottom: var(--spacing-core-6);
    }

    @include mq.mq($from: large) {
        display: flex;
    }
}

.logoImg {
    width: 200px;
    height: 36px;

    @include mq.mq($until: medium) {
        width: 150px;
    }
}

.recipeLink {
    transition: transform .2s ease-in-out;

&:hover {
    transform: translateX(2px);
}
}

.searchInput {
    position: relative;
    width: 708px;
    margin: 0 auto;

    @include mq.mq($until: medium) {
        width: 82%;
    }

    @include mq.mq($until: small) {
        width: 92%;
    }
}
