.root {
    border-radius: var(--border-radius-core-s);
}

.primary {
    background-color: var(--color-action-secondary-default);
    color: var(--color-foreground-inverted-default);

    &:active,
    &:hover {
        background-color: var(--color-action-secondary-hover);
    }

    svg {
        fill: var(--color-foreground-inverted-default)
    }

    &.disabled {
        background-color: var(--color-background-secondary-default);
        color: var(--color-background-quaternary-default);
        cursor: default;

        svg {
            fill: var(--color-background-quaternary-default);
        }
    }
}

.secondary {
    box-shadow: 0 0 0 var(--border-width-core-thick) var(--color-action-secondary-default) inset;
    color: var(--color-action-secondary-default);

    &:active,
    &:hover {
        background-color: var(--color-action-secondary-default);
        color: var(--color-foreground-inverted-default);
    }

    svg {
        fill: var(--color-foreground-inverted-default)
    }

    &.disabled {
        background-color: var(--color-background-secondary-default);
        color: var(--color-background-quaternary-default);
        cursor: default;

        svg {
            fill: var(--color-background-quaternary-default);
        }
    }
}

