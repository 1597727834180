$checkbox-scale: 0.6;
$checkbox-check-scale: 0.8;

/* 
* Pantry checkbox does not merge incoming classnames on CheckboxTick and CheckboxLabel
* Therefore, we need to apply the styles directly to the checkbox class and fix the Pantry checkbox
*/
.checkbox {
    color: var(--color-foreground-primary-default);
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    input {
        position: relative;
        transform-origin: center left;
        transform: scale($checkbox-scale);
        top: auto;
        border-radius: 0;
        border:2px solid #e0e0e0;
        box-shadow: none;
        
        ~[id="check"] {
            transform-origin: center left;
            transform: scale($checkbox-check-scale);
            margin: calc(var(--spacing-core-1) * $checkbox-scale);
        }

        ~ label {
            padding: 0;
        }

        &:checked {
            background-color: var(--color-background-primary-default);

            ~[id="check"]{
                color: var(--color-foreground-primary-default);
            }

            ~label {
                color: var(--color-action-primary-default);
                font-weight: bold;
            }
        }
    }

    &:hover {
        input,
        input:checked {
            background-color: var(--color-background-primary-hover);
        }
    }
}