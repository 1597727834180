@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

$input-transition: 250ms ease;

.root {
    display: flex;
    flex-direction: row;
    height: 56px;
}

.big {
    height: 64px;

    @include mq.mq($from: medium) {
        height: 88px;
    }

    .button {
        width: 64px;

        @include mq.mq($from: medium) {
            width: 88px;
        }
    }
}

.button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    height: 100%;

    .icon {
        fill: var(--color-foreground-inverted-default)
    }
}

.input {
    box-shadow: none;
    border-radius: var(--border-radius-core-s) 0 0 var(--border-radius-core-s);
    background-color: var(--color-background-primary-default);
    height: 100%;
    max-height: 100%;
    font-weight: bold;
    transition: border-color $input-transition, background-color $input-transition,
        box-shadow $input-transition;

    &::placeholder {
        font-size: 16px;
        font-weight: normal;
    }

    &:focus-visible,
    &:active,
    &:hover {
        border-color: transparent;
        background-color: var(--color-background-primary-default);
        box-shadow: 0 15px 18px 0 rgb(0 0 0 / 5%);
        outline: none;
    }

    &:focus {
        border-color: transparent;
        box-shadow: 0 15px 18px 0 rgb(0 0 0 / 5%);
    }
}

.inputIcon {
    cursor: pointer;
}

.inputGroup {
    flex-grow: 1;
    height: 100%;
}

