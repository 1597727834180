@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

.root {
    position: relative;
    padding-top: var(--spacing-core-6);
    padding-bottom: var(--spacing-core-6);
}

.titleRow {
    position: relative;
    
    * {
        text-align: center;
    }
}

.seeMore {
    text-align: center;
    margin-top: var(--spacing-core-2);
    width: 100%;

    @include mq.mq($from: "large") {
        position: absolute;        
        right: 0;
        top: 0;
        width: auto;
    }
}