.root {
    flex: 1 0 100%;
    margin: var(--spacing-core-8) 0;
    border-top: var(--border-width-core-regular) solid var(--color-background-secondary-hover);
    margin-bottom: 0;
}

.anchor {    
    position: relative;
    display: block;
    padding: var(--spacing-core-4) var(--spacing-core-4) var(--spacing-core-4) 0;
    color: var(--color-foreground-primary-default);
    line-height: var(--spacing-core-6);
    border-bottom: var(--border-width-core-regular) solid var(--color-background-secondary-hover);
    text-decoration: none;
    transition: all 200ms ease-in-out;
    cursor: pointer;

    &:hover {
        color: var(--color-foreground-primary-hover);;
        transform: translateX(2px);
    }
}

.chevron {
    position: absolute;
    top: 0;
    right: 5px;
    bottom: 0;
    margin: auto;
}
