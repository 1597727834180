.root {
    padding: 0;
    background: none;
    border: none;
    margin-top: var(--spacing-core-4);
    cursor: pointer;
}

.icon {
    margin-right: var(--spacing-core-3);
}

.outline {
    width: 100%;
    display: flex;
    align-items: center;
    outline: none;
}
