@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

@function backgroundgradient($initialAlpha) {
    @return linear-gradient(
        180deg,
        rgba(0, 0, 0, $initialAlpha) 0%,
        rgba(0, 0, 0, 0) 100%
    );
}

.root {
    margin-top: calc(var(--spacing-core-6) * -1);
}

.recipeGroupHeaderLaneTitle {
    margin-top: var(--spacing-core-6);
    margin-bottom: var(--spacing-core-4);
    overflow-wrap: anywhere;
    word-break: normal;
}

.recipeLinkContainer {
    text-align: right;
    color: var(--color-foreground-inverted-default);
    position: absolute;
    min-height: 153px;
    top: 0;
    right: 0;
    width: 100%;
    padding: var(--spacing-core-6) var(--spacing-core-8) 0;
    background: backgroundgradient(0.72);

    @include mq.mq($until: medium) {
        height: 100px;
        background: backgroundgradient(0.4);
        padding: var(--spacing-core-2) var(--spacing-core-2) 0;
        left: 0;
    }
}

.recipeLink {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: var(--spacing-core-2);
    transition: transform 200ms ease-in-out;
    color: var(--color-foreground-inverted-default);
    text-decoration: none;
    
    &:hover {
        transform: translateX(2px);
    }
}

.topAdContainer {
    margin: var(--spacing-core-6) auto;
}

.bottomAdTabletContainer {
    margin: var(--spacing-core-8) auto;
}

.bottomAdContainer {
    margin: var(--spacing-core-8) auto;
}

.bottomAdContainerMobile {
    margin: var(--spacing-core-8) auto;
}
