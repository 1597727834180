@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

$recipe-header-desktop-height: 720px;
$recipe-image-height-tablet: 420px;
$recipe-image-height-mobile: 288px;

.container {
    &Mobile {
        @include mq.mq($from: large) {
            padding: 0;
            max-width: none;
            margin: 0;
            flex-grow: 1;
            flex-basis: 50%;
        }
    }

    &Desktop {
        position: relative;
        
        @include mq.mq($until: medium) {
            padding: 0;
            max-width: none;
            margin: 0;
        }
    }
}

.root {
    @include mq.mq($from: large) {
        min-height: $recipe-header-desktop-height;
        display: flex;
        flex-direction: row-reverse;
        align-items: flex-start;
    }
}

.toolbar {
    width: 100%;
    display: flex;
    gap: var(--spacing-core-4);
    bottom: var(--spacing-core-12);
    position: absolute;
    left: var(--spacing-core-4);

    @include mq.mq($from: medium) {
        left: var(--spacing-core-6);
    }

    @include mq.mq($from: large) {
        justify-content: flex-end;
        top: 0;
        left: 0;
        padding: var(--spacing-core-6) var(--spacing-core-6)
            var(--spacing-core-6) var(--spacing-core-8);
    }
}

.logo {
    width: calc(100% - var(--spacing-core-8));
}

.favorite {
    height: var(--sizing-core-14);
    display: none;

    @include mq.mq($from: large) {
        display: block;
    }
}

.video {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
}

.videoWithBrand {
    top: 40%;

    @include mq.mq($from: medium) {
        top: 50%;
    }
}

.content {
    position: relative;
    background: var(--color-background-primary-default);
    box-shadow: 0 6px 80px rgb(0 0 0 / 10%);
    border-radius: var(--border-radius-core-m);
    padding: var(--spacing-core-4) var(--spacing-core-4) var(--spacing-core-10) var(--spacing-core-4);
    margin-top: -32px;

    @include mq.mq($from: medium) {
        padding: var(--spacing-core-4) var(--spacing-core-6) var(--spacing-core-10)
            var(--spacing-core-6);
        border-radius: var(--border-radius-core-l);
    }

    @include mq.mq($from: large) {
        position: static;
        min-height: $recipe-header-desktop-height;
        margin-top: 0;
        border-radius: 0;
        padding: var(--spacing-core-6) var(--spacing-core-20);
    }
}

.contentIndex {
    z-index: 0;
}

.media {
    position: relative;
    height: $recipe-image-height-mobile;
    overflow: hidden;

    @include mq.mq($from: medium) {
        height: $recipe-image-height-tablet;
    }

    @include mq.mq($from: large) {
        flex-grow: 1;
        flex-basis: 50%;
        min-height: calc($recipe-header-desktop-height - var(--spacing-core-16));
        border-radius: 0 0 128px;
    }
}
