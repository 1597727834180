@keyframes appear {
    0% {
        opacity: 0;
        transform: translateY(-7px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.root {
    position: relative;
    width: 100%;
}

.suggestionsWrapper {
    position: absolute;
    z-index: 10;
    top: calc(100% + var(--spacing-core-2));
    left: 0;
    width: 100%;
    animation: appear 250ms ease-out;
    animation-iteration-count: 1;
}
